@import '../main.scss';
@import '../theme.scss';

.listWrapper, .cardWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.newRequestButton, .newRequestButton:disabled, .newRequestButton:disabled:hover {
  background: $primaryColorACS !important;
  color: $white !important;
}

.specialRequestButton, .specialRequestButton:disabled, .specialRequestButton:disabled:hover {
  background: $darkBlue !important;
  color: $white !important;
}

.listWrapper {
  margin-top: 1em;
  margin-bottom: 2em;
}

.repartitionsContainer {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
}

.customList {
  width: 35%;
  height: auto;
  margin-bottom: 0;
}

.customCard {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.customSelect {
  width: calc(100% - 65px);
}

.customCard > div {
  min-width: 20px;
  padding: 10px !important;
}

li {
  padding-bottom: 0 !important;
}

.flexCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .customList {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .customList {
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .customList {
    width: 55%;
  }
}

@media only screen and (max-width: 800px) {
  .customList {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .customList {
    width: 65%;
  }
}

@media only screen and (max-width: 620px) {
  .customList {
    width: 70%;
  }
}

@media only screen and (max-width: 520px) {
  li {
    display: flex !important;
    flex: 1 1 0;
    padding: 16px 0 !important;
  }

  li > div {
    width: 100%;
  }

  .customList {
    width: 100%;
  }

  .customCard > div {
    padding: 5px 24px !important;
  }

  .ant-list-lg .ant-list-item {
    padding: 16px 0 !important;
  }
}